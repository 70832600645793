// export const Words = [
// 	"cat",
// 	"dog",
// 	"mouse"
// ]

export const Words = [
  {
    Words: "Vietnam"
  },
  {
    Words: "China"
  },
  {
    Words: "India"
  },
  {
    Words: "America"
  },
  {
    Words: "Europe"
  },
  {
    Words: "Africa"
  },
  {
    Words: "New York"
  },
  {
    Words: "California"
  },
  {
    Words: "Texas"
  },
  {
    Words: "moon"
  },
  {
    Words: "sun"
  },
  {
    Words: "earth"
  },
  {
    Words: "universe"
  },
  {
    Words: "planet"
  },
  {
    Words: "Trump"
  },
  {
    Words: "Obama"
  },
  {
    Words: "queen"
  },
  {
    Words: "Bush"
  },
  {
    Words: "JFK"
  },
  {
    Words: "Gandhi"
  },
  {
    Words: "Tiger Wood"
  },
  {
    Words: "Lincoln"
  },
  {
    Words: "Washington"
  },
  {
    Words: "Einstein"
  },
  {
    Words: "Hillary"
  },
  {
    Words: "pen"
  },
  {
    Words: "phone"
  },
  {
    Words: "book"
  },
  {
    Words: "mouse"
  },
  {
    Words: "keyboard"
  },
  {
    Words: "monitor"
  },
  {
    Words: "computer"
  },
  {
    Words: "couch"
  },
  {
    Words: "flower"
  },
  {
    Words: "plant"
  },
  {
    Words: "cup"
  },
  {
    Words: "mirrow"
  },
  {
    Words: "bed"
  },
  {
    Words: "chair"
  },
  {
    Words: "desk"
  },
  {
    Words: "table"
  },
  {
    Words: "lamp"
  },
  {
    Words: "jam"
  },
  {
    Words: "apple"
  },
  {
    Words: "water"
  },
  {
    Words: "egg"
  },
  {
    Words: "chicken"
  },
  {
    Words: "cow"
  },
  {
    Words: "sheep"
  },
  {
    Words: "mushroom"
  },
  {
    Words: "fish"
  },
  {
    Words: "penguin"
  },
  {
    Words: "panda"
  },
  {
    Words: "basketball"
  },
  {
    Words: "baseball"
  },
  {
    Words: "soccer"
  },
  {
    Words: "football"
  },
  {
    Words: "airplane"
  },
  {
    Words: "bridge"
  },
  {
    Words: "cage"
  },
  {
    Words: "job"
  },
  {
    Words: "ship"
  },
  {
    Words: "car"
  },
  {
    Words: "bus"
  },
  {
    Words: "grandmother"
  },
  {
    Words: "grandfather"
  },
  {
    Words: "mother"
  },
  {
    Words: "father"
  },
  {
    Words: "son"
  },
  {
    Words: "daughter"
  },
  {
    Words: "sister"
  },
  {
    Words: "brother"
  },
  {
    Words: "shrine"
  },
  {
    Words: "temple"
  },
  {
    Words: "snow"
  },
  {
    Words: "rain"
  },
  {
    Words: "river"
  },
  {
    Words: "lake"
  },
  {
    Words: "volcano"
  },
  {
    Words: "fire"
  },
  {
    Words: "ticket"
  },
  {
    Words: "gas"
  },
  {
    Words: "fart"
  },
  {
    Words: "sunshine"
  },
  {
    Words: "rainbow"
  },
  {
    Words: "lion"
  },
  {
    Words: "dog"
  },
  {
    Words: "cat"
  },
  {
    Words: "Christmas"
  },
  {
    Words: "Thanksgiving"
  },
  {
    Words: "tomb"
  },
  {
    Words: "coffin"
  },
  {
    Words: "moisturizer"
  },
  {
    Words: "toilet"
  },
  {
    Words: "bathroom"
  },
  {
    Words: "kitchen"
  },
  {
    Words: "grass"
  },
  {
    Words: "tree"
  },
  {
    Words: "flag"
  },
  {
    Words: "shoe"
  },
  {
    Words: "blood"
  },
  {
    Words: "finger"
  },
  {
    Words: "leg"
  },
  {
    Words: "foot"
  },
  {
    Words: "chest"
  },
  {
    Words: "arm"
  },
  {
    Words: "head"
  },
  {
    Words: "neck"
  },
  {
    Words: "hair"
  },
  {
    Words: "ear"
  },
  {
    Words: "eye"
  },
  {
    Words: "nose"
  },
  {
    Words: "tongue"
  },
  {
    Words: "glasses"
  },
  {
    Words: "socks"
  },
  {
    Words: "perfume"
  },
  {
    Words: "book"
  },
  {
    Words: "virus"
  },
  {
    Words: "Internet"
  },
  {
    Words: "Kiss"
  },
  {
    Words: "Lunch"
  },
  {
    Words: "Ski"
  },
  {
    Words: "Dinasaur"
  },
  {
    Words: "Rubber"
  },
  {
    Words: "airport"
  },
  {
    Words: "ALARM"
  },
  {
    Words: "ALIEN"
  },
  {
    Words: "AMAZON"
  },
  {
    Words: "AMBULANCE"
  },
  {
    Words: "ANGEL"
  },
  {
    Words: "ARMY"
  },
  {
    Words: "book"
  },
  {
    Words: "bottle"
  },
  {
    Words: "BREAKFAST"
  },
  {
    Words: "BUTTON"
  },
  {
    Words: "CAKE"
  },
  {
    Words: "CALCULATOR"
  },
  {
    Words: "CALENDAR"
  },
  {
    Words: "GOLF"
  },
  {
    Words: "CAMERA"
  },
  {
    Words: "CAMP"
  },
  {
    Words: "CARD"
  },
  {
    Words: "CASINO"
  },
  {
    Words: "CARROT"
  },
  {
    Words: "CHALK"
  },
  {
    Words: "CEMETERY"
  },
  {
    Words: "CAVE"
  },
  {
    Words: "CHESS"
  },
  {
    Words: "CHOCOLATE"
  },
  {
    Words: "CHRISTMAS"
  },
  {
    Words: "CHURCH"
  },
  {
    Words: "CIRCLE"
  },
  {
    Words: "CLASS"
  },
  {
    Words: "school"
  },
  {
    Words: "COFFEE"
  },
  {
    Words: "CLOUD"
  },
  {
    Words: "CONCERT"
  },
  {
    Words: "drug"
  },
  {
    Words: "COWBOY"
  },
  {
    Words: "CONDUCTOR"
  },
  {
    Words: "CRYSTAL"
  },
  {
    Words: "CRIME"
  },
  {
    Words: "CURRY"
  },
  {
    Words: "DENTIST"
  },
  {
    Words: "DEATH"
  },
  {
    Words: "DANCE"
  },
  {
    Words: "DIAMOND"
  },
  {
    Words: "DINNER"
  },
  {
    Words: "DESIGN"
  },
  {
    Words: "DISEASE"
  },
  {
    Words: "DREAM"
  },
  {
    Words: "DRAGON"
  },
  {
    Words: "DOLLAR"
  },
  {
    Words: "DOCTOR"
  },
  {
    Words: "DREAM"
  },
  {
    Words: "EARTHQUAKE"
  },
  {
    Words: "EDUCATION"
  },
  {
    Words: "ENGINE"
  },
  {
    Words: "EXPERT"
  },
  {
    Words: "FARM"
  },
  {
    Words: "FLOWER"
  },
  {
    Words: "FOREST"
  },
  {
    Words: "GARDEN"
  },
  {
    Words: "GANGSTER"
  },
  {
    Words: "FURNITURE"
  },
  {
    Words: "GENIUS"
  },
  {
    Words: "GATE"
  },
  {
    Words: "GOVERNMENT"
  },
  {
    Words: "GUITAR"
  },
  {
    Words: "HOSPITAL"
  },
  {
    Words: "HOTEL"
  },
  {
    Words: "GUN"
  },
  {
    Words: "Knife"
  },
  {
    Words: "HEAT"
  },
  {
    Words: "JAIL"
  },
  {
    Words: "ICE"
  },
  {
    Words: "INCOME"
  },
  {
    Words: "JOKER"
  },
  {
    Words: "KUNG FU"
  },
  {
    Words: "Wikipedia"
  },
  {
    Words: "LAUNDRY"
  },
  {
    Words: "LAWYER"
  },
  {
    Words: "LIGHT"
  },
  {
    Words: "LIGHTNING"
  },
  {
    Words: "LIMOUSINE"
  },
  {
    Words: "LEATHER"
  },
  {
    Words: "LIBRARY"
  },
  {
    Words: "LIFT"
  },
  {
    Words: "MAGAZINE"
  },
  {
    Words: "MAGIC"
  },
  {
    Words: "Basket"
  },
  {
    Words: "MAIL"
  },
  {
    Words: "MAILBOX"
  },
  {
    Words: "MAID"
  },
  {
    Words: "MARATHON"
  },
  {
    Words: "MARCH"
  },
  {
    Words: "MASK"
  },
  {
    Words: "MILK"
  },
  {
    Words: "MONEY"
  },
  {
    Words: "MOTION"
  },
  {
    Words: "NIGHT"
  },
  {
    Words: "NINJA"
  },
  {
    Words: "OIL"
  },
  {
    Words: "OFFICE"
  },
  {
    Words: "Facebook"
  },
  {
    Words: "OPERA"
  },
  {
    Words: "PAPER"
  },
  {
    Words: "PAD"
  },
  {
    Words: "watch"
  },
  {
    Words: "PIZZA"
  },
  {
    Words: "Pasta"
  },
  {
    Words: "rice"
  },
  {
    Words: "robot"
  },
  {
    Words: "santa"
  },
  {
    Words: "SCHOOL"
  },
  {
    Words: "SMOKE"
  },
  {
    Words: "SEX"
  },
  {
    Words: "STADIUM"
  },
  {
    Words: "STATE"
  },
  {
    Words: "SUGAR"
  },
  {
    Words: "SUMMER"
  },
  {
    Words: "winter"
  },
  {
    Words: "spring"
  },
  {
    Words: "SWEAT"
  },
  {
    Words: "SWIM"
  },
  {
    Words: "TATTOO"
  },
  {
    Words: "TAX"
  },
  {
    Words: "TEA"
  },
  {
    Words: "TEACHER"
  },
  {
    Words: "THUNDER"
  },
  {
    Words: "THRONE"
  },
  {
    Words: "TOWER"
  },
  {
    Words: "TOY"
  },
  {
    Words: "TRAIN"
  },
  {
    Words: "TRIANGLE"
  },
  {
    Words: "TRUCK"
  },
  {
    Words: "UMBRELLA"
  },
  {
    Words: "UNDERWEAR"
  },
  {
    Words: "UNICORN"
  },
  {
    Words: "UNIVERITY"
  },
  {
    Words: "VACATION"
  },
  {
    Words: "VACUUM"
  },
  {
    Words: "VALENTINE"
  },
  {
    Words: "VAMPIRE"
  },
  {
    Words: "VOICE"
  },
  {
    Words: "WAITRESS"
  },
  {
    Words: "WAKE"
  },
  {
    Words: "WALL"
  },
  {
    Words: "WALRUS"
  },
  {
    Words: "WAR"
  },
  {
    Words: "WASTE"
  },
  {
    Words: "WATCH"
  },
  {
    Words: "WAVE"
  },
  {
    Words: "WAX"
  },
  {
    Words: "WEATHER"
  },
  {
    Words: "WEDDING"
  },
  {
    Words: "WEIGHT"
  },
  {
    Words: "WHEEL"
  },
  {
    Words: "WHIP"
  },
  {
    Words: "WHISTLE"
  },
  {
    Words: "WIND"
  },
  {
    Words: "WINDOW"
  },
  {
    Words: "WINE"
  },
  {
    Words: "WINTER"
  },
  {
    Words: "WIRE"
  },
  {
    Words: "WITCH"
  },
  {
    Words: "WIZARD"
  },
  {
    Words: "WOMAN"
  },
  {
    Words: "WOOD"
  },
  {
    Words: "WORD"
  },
  {
    Words: "WORM"
  },
  {
    Words: "WOUND"
  },
  {
    Words: "ZEBRA"
  },
  {
    Words: "ZOMBIE"
  },
  {
    Words: "hotdog"
  },
  {
    Words: "bird"
  },
  {
    Words: "ball"
  },
  {
    Words: "black"
  },
  {
    Words: "Lizard"
  },
  {
    Words: "Boat"
  },
  {
    Words: "Aquarium"
  },
  {
    Words: "Nightmare"
  },
  {
    Words: "Drug"
  },
  {
    Words: "Athlete"
  },
  {
    Words: "Train"
  },
  {
    Words: "Bullet"
  },
  {
    Words: "Sleep"
  }
]